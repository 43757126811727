@font-face {
  font-family: CenturyGothic;
  src: url('./fonts/GOTHIC.woff');
}

@font-face {
  font-family: CenturyGothic-Bold;
  src: url('./fonts/GOTHICB.woff');
}

@font-face {
  font-family: CenturyGothic-Bold-Italic;
  src: url('./fonts/GOTHICBI.woff');
}

@font-face {
  font-family: CenturyGothic-Italic;
  src: url('./fonts/GOTHICI.woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: CenturyGothic;
  color: #0879A6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: #0879A6;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #54A62E;
}

.swal2-popup {
  border-radius: 40px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.logoOzel {
  width: 20vw;
  height: calc(20vw / 4.695);

  margin: auto auto auto 3%;

  background-image: url('./images//logoOzelCompletoVerde.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.soporte {
  margin: auto;
  padding: 5px 20px;

  border: none;
  outline: none;
  border-radius: 20px;

  background-color: #0879A6;

  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .logoOzel {
    width: 35vw;
    height: calc(35vw / 4.695);
  }
}

@media screen and (max-width: 540px) {
  .soporte {
    font-size: 14px;
  }

  .logoOzel {
    width: 35vw;
    height: calc(35vw / 4.695);
  }
}
